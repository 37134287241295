// Grid.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Grid.css';

const Grid = ({ title }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    console.log('Grid montado');
    return () => {
      console.log('Grid desmontado');
    };
  }, []);

  return (
    <div className="grid">
      <h1>{title}</h1>
      <p>El contador es: {count}</p>
      <button onClick={() => setCount(count + 1)}>Incrementar</button>
    </div>
  );
};

Grid.propTypes = {
  title: PropTypes.string.isRequired,
};

Grid.defaultProps = {
  title: 'Título por defecto',
};

export default Grid;
