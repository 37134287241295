// TestComponent.js
import React from 'react';
import Grid from './components/Grid/Grid';

const TestComponent = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Prueba de SaveDeliveryInActive</h1>
      <Grid />
    </div>
  );
};

export default TestComponent;
