import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Importación correcta de jwtDecode
import config from "src/config";

// Función para verificar si el token es válido y obtener el estado inicial
const initialStateFunc = () => {
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const token = storedUser ? storedUser.jwt : null;

  // Estado inicial predeterminado
  const initialState = {
    loading: false,
    user: null, // Usuario nulo si no se cumple la validación
    error: null,
  };

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Hora actual en segundos
      // Verificar si el token ha expirado
      if (decodedToken.exp > currentTime) {
        initialState.user = storedUser; // Establece el usuario si el token es válido
      }
    } catch (error) {
      console.error("Error al decodificar el token:", error);
    }
  }

  return initialState;
};

// Acción para manejar el login del usuario
export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (userCredentials) => {
    try{
    const request = await axios.post(`${config.API_BASE_URL}/api/users/auth`, userCredentials);
    const response = await request.data;
    console.log(response);
    localStorage.setItem('user', JSON.stringify(response));
    localStorage.setItem('authToken', response.jwt);
    return response;
  }catch(error){
    alert("Credenciales invalidas")
  }
  }
);

// Acción para el logout
export const logoutUser = createAsyncThunk('user/logoutUser', async () => {
    // Eliminar datos del localStorage
    localStorage.removeItem('user');
    localStorage.removeItem('authToken');
    return null; // Retorna null para que el estado de Redux también se actualice
  });

const userSlice = createSlice({
  name: 'user',
  initialState: initialStateFunc(), // Llama a la función para obtener el estado inicial
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        console.log(action.error.message);
        if (action.error.message === 'Request failed with status code 401') {
          state.error = 'Access Denied! Invalid Credentials';
        } else {
          state.error = action.error.message;
        }
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.user = null; // Eliminar el usuario de Redux al hacer logout
      });;
  },
});

export default userSlice.reducer;
